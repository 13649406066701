import React, { PureComponent } from "react";

import Main from "./Main";
import Audio from "./Audio/Audio";
import Export from "./Export";
import Basics from "./Basics";
import Video from "./Video";

import ExportSettings from "./ExportSettings";

import AppAppBar from "../modules/views/AppAppBar";
import AppFooter from "../modules/views/AppFooter";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SEO from "../SEO";
export default class Tutorial extends PureComponent {
    render() {
        return (
            <React.Fragment>
                <AppAppBar />
                <SEO title="Tutorial" description="How musicvid.org works" />
                <Router>
                    <Switch>
                        <Route
                            path="/tutorial/basics"
                            exact
                            component={Basics}
                        />
                        <Route
                            path="/tutorial/audio_analysers"
                            component={Audio}
                        />

                        <Route
                            path="/tutorial/export-settings"
                            exact
                            component={ExportSettings}
                        />

                        <Route path="/tutorial/video" exact component={Video} />
                        <Route
                            path="/tutorial/exporting"
                            exact
                            component={Export}
                        />
                        <Route component={Main} />
                    </Switch>
                </Router>
                <AppFooter />
            </React.Fragment>
        );
    }
}
