import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LayoutBody from "./modules/components/LayoutBody";
import Typography from "./modules/components/Typography";
import ImageVideo from "./modules/views/ImageVideo";
import { HorizontalAd } from "home/Ad";
import AppAppBar from "./modules/views/AppAppBar";
import AppFooter from "./modules/views/AppFooter";
import SEO from "./SEO";
const styles = (theme) => ({
    root: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4)
    },
    images: {
        marginTop: theme.spacing(4),
        display: "flex",
        flexWrap: "wrap"
    }
});

function ProductCategories(props) {
    const { classes } = props;

    const images = [
        {
            url: "img/templates/dark.png",
            src: "",
            title: "Dark Nation Temp",
            width: "32%",
            templateName: "editor?project=087a5076-d0d7-4b3d-8cba-57cf9f601ff5",
            attrib: "@Dark Nation",
            attribUrl: " https://www.youtube.com/watch?v=dO8dQ0S9oJ4",
            license: "Free"
        },
        {
            url: "img/templates/more.png",
            src: "vid/templates/wavy_2.mp4",
            title: "Wavy",
            width: "34%",
            templateName: "editor?project=908942f8-0eaa-4fca-b1f8-32d9469c76c5",
            attrib: "@Rhyvix",
            attribUrl: "https://youtu.be/OL2niANb5UY",
            license: "Free"
        },
        {
            url: "img/templates/wavy.png",
            src: "vid/templates/lue_2.mp4",
            title: "LUE",
            width: "34%",
            templateName: "editor?project=b82aaeb7-58ec-4cf3-8813-c91c902f2b7a",
            attrib: "@CRØWN クラウン",
            attribUrl: "https://www.youtube.com/watch?v=h4hLtSoKFbs",
            license: "Free"
        },
        {
            url: "img/templates/glow.png",
            src: "vid/templates/glow_2.mp4",
            title: "Glow",
            width: "30%",
            templateName: "editor?project=95eac693-1c84-4595-b8b9-d9f83b9b4cc2",
            attrib: "@ekkzen",
            attribUrl: "https://www.youtube.com/watch?v=yRbFgvkgRU8",
            license: "Free"
        },

        {
            url: "img/templates/skewed.png",
            src: "vid/templates/skewed_2.mp4",
            title: "Skewed Background",
            width: "40%",
            templateName: "editor?project=527b5897-d7a4-4919-97a2-b2f65d800666",
            attrib: "@iGerman",
            attribUrl:
                "https://www.youtube.com/channel/UCcAlTqd9zID6aNX3TzwxJXg",
            twitter: "https://www.youtube.com/channel/UCaUHXjG_njW0JJOqYrHFXug",
            license: "Free"
        },
        {
            url: "img/templates/notnation.png",
            src: "vid/templates/notnation_2.mp4",
            title: "Not Nation",
            width: "30%",
            templateName: "editor?project=fe23f4d6-9ad8-419a-868b-1e711e2e2aba",
            attrib: "@iGerman",
            attribUrl:
                "https://www.youtube.com/channel/UCaUHXjG_njW0JJOqYrHFXug",
            twitter: "",
            license: "Free"
        },

        {
            url: "img/templates/signature.png",
            src: "vid/templates/Signature_2.mp4",
            title: "Signature",
            width: "40%",
            templateName: "editor?project=681ce88e-d887-4652-81e2-df6c73cd163e",
            attrib: "@iGerman",
            attribUrl:
                "https://www.youtube.com/channel/UCcAlTqd9zID6aNX3TzwxJXg",
            license: "Free"
        }
    ];

    /*
     {
      url:'img/templates/Noise.png',
      src: "vid/templates/NoiseY.mp4",
      title: 'Electric Noise',
      width: '40%',
      templateName: "editor?template=Noise",
      attrib: "nmz (@stormoid)",
      attribUrl: "https://www.shadertoy.com/user/nimitz",
      twitter: "https://twitter.com/stormoid",
      license: "Attribution Required"
    },


  */

    /*url: 'img/templates/SimplicityGalaxy.png',
      title: 'Simplicity Galaxy',
      width: '20%',
      templateName: "SimplicityGalaxy",
      attrib: "@CBS",
      attribUrl: "https://www.shadertoy.com/user/CBS",
      license: "FREE" */
    return (
        <div>
            <AppAppBar />

            <SEO
                title="Free Templates"
                description="Choose from user created templates as a starting point and create your own unique HD visualizer."
            />
            <LayoutBody
                className={classes.root}
                component="section"
                width="large"
                id="FeaturedProjectsTitle"
            >
                <HorizontalAd slot="6235846189" />

                <Typography
                    variant="h4"
                    marked="center"
                    align="center"
                    component="h2"
                >
                    User created templates
                </Typography>

                <div className={classes.images}>
                    {images.map((image) => (
                        <ImageVideo
                            image={image}
                            key={image.title}
                        ></ImageVideo>
                    ))}
                </div>

                <div
                    style={{
                        width: "100%",
                        textAlign: "center",
                        margin: 20,
                        borderBottom: "1px solid rgba(0,0,0,0.15)"
                    }}
                >
                    <Typography>-</Typography>
                </div>

                <HorizontalAd slot="6235846189" />
            </LayoutBody>
            <AppFooter />
        </div>
    );
}

ProductCategories.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCategories);
