import React from "react";

const client = "ca-pub-2957457343597658";
const defaultSlot =  "5078451841";
const landingSlot =  "5078451841";

export default class Ad extends React.Component {
    state = { didLoad: false };
    componentDidMount() {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            this.setState({ didLoad: true });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return (
            <>
                {!this.props.dontPlead && this.state.didLoad && (
                    <div
                        style={{
                            position: "absolute",
                            top: "30%",
                            color: "rgba(255, 255, 255, 0.55)",
                            textAlign: "center",
                            userSelect: "none"
                        }}
                    >
                        .
                    </div>
                )}

                <div className="ad" style={{ margin: this.props.margin }}>
                    <ins
                        className="adsbygoogle"
                        style={{
                            display: "block",
                            zIndex: 3,
                            margin: this.props.margin || ""
                        }}
                        data-ad-client={client}
                        data-ad-slot={this.props.slot || defaultSlot}
                        data-ad-format={this.props.format}
                        data-full-width-responsive="true"
                        data-adtest={this.props.isTest  ? "on" : "off"}
                    ></ins>
                </div>
            </>
        );
    }
}

export class LandingAd extends React.Component {
    state = { didLoad: false };
    componentDidMount() {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            this.setState({ didLoad: true });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return (
            <div
                style={{
                    width: 250,
                    height: 500,
                    margin: 50,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0,0,0,0.04)"
                }}
            >
                <div className="ad">
                    <ins
                        className="adsbygoogle"
                        style={{
                            display: "block",
                            width: 250,
                            height: 500
                        }}
                        data-ad-client={client}
                        data-ad-slot={landingSlot}
                        data-adtest={this.props.isTest  ? "on" : "off"}
                    ></ins>
                </div>
            </div>
        );
    }
}

export class HorizontalAd extends React.Component {
    render() {
        return (
            <div
                style={{
                    width: this.props.width || "100%",
                    backgroundColor: "rgba(0,0,0,0.02)",
                    fontFamily: "Roboto",
                    fontWeight: 500,

                    marginBottom: 30
                }}
            >
                <div style={{ width: "100%", opacity: 0.7 }}>Advertisement</div>
                <div
                    style={{
                        width: this.props.width || "100%",
                        height: this.props.height || 200
                    }}
                >
                    <Ad
                        margin="auto"
                        slot={this.props.slot}
                        format={"auto"}
                        dontPlead
                    />
                </div>
            </div>
        );
    }
}

export class VerticalAd extends React.Component {
    render() {
        return (
            <div
                style={{
                    backgroundColor: "rgba(0,0,0,0.05)",
                    fontFamily: "Roboto",
                    height: this.props.height || "90%",
                    margin: this.props.margin || 30,
                    color: this.props.color || "black"
                }}
            >
                <div style={{ width: "100%", opacity: 0.75 }}>
                    Advertisement
                </div>
                <div
                    style={{
                        width: this.props.width || 250,
                        backgroundColor: "rgba(0,0,0,0.01)"
                    }}
                >
                    <Ad slot={this.props.slot} format={"auto"} />
                </div>
            </div>
        );
    }
}
