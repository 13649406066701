import React, { PureComponent } from "react";
import Typography from "../modules/components/Typography";
import LayoutBody from "../modules/components/LayoutBody";

import { HorizontalAd } from "../Ad";

export default class Export extends PureComponent {
    render() {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around"
                }}
            >
                <LayoutBody margin marginBottom>
                    <Typography
                        variant="h4"
                        gutterBottom
                        marked="center"
                        align="center"
                    >
                        Video Tutorial
                    </Typography>
                    <div style={{ width: 800, height: 450 }}>
                        <iframe
                            src={"https://www.youtube.com/embed/i2wmETo74v0"}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            title="video"
                        ></iframe>
                    </div>
                    <HorizontalAd slot="4350272656" />
                </LayoutBody>
            </div>
        );
    }
}
