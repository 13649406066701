import React, { PureComponent } from "react";
import Typography from "../modules/components/Typography";
import LayoutBody from "../modules/components/LayoutBody";
import exp from "./exp.PNG";

import { VerticalAd } from "home/Ad";

export default class Export extends PureComponent {
    render() {
        return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div>
                    <VerticalAd slot="4763838645" />
                    <VerticalAd slot="4763838645" />
                </div>
                <LayoutBody margin marginBottom>
                    <Typography
                        variant="h4"
                        gutterBottom
                        marked="center"
                        align="center"
                    >
                        Export Tutorial
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        General
                    </Typography>
                    Some points to be aware of before exporting:
                    <ul>
                        <li>
                            Export is currently working on Chrome and Firefox.
                            Other browsers may work as well, but aren't tested
                            by me
                        </li>
                        <li>
                            Check{" "}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.whatismybrowser.com/"
                            >
                                https://www.whatismybrowser.com/
                            </a>{" "}
                            to see if your browser is up to date, some features
                            used in exporting is quite new and require an up to
                            date browser
                        </li>

                        <li>Mobile is not supported</li>

                        <li>
                            The exporting is done on your computer, no server is
                            involved.
                        </li>
                        <li>
                            The exporting doesn't write to file until the video
                            is done, so it uses a lot of RAM, make sure to close
                            out other tabs and programs
                        </li>
                        <li>
                            There is a memory limit on browsers, so if your
                            export is too long/uses too high resolution/has too
                            high bitrate, it might not work
                        </li>
                    </ul>
                    Check out the{" "}
                    <a href="/tutorial/export-settings">export settings</a> for
                    more information
                </LayoutBody>
                <div>
                    <VerticalAd slot="4763838645" />
                    <VerticalAd slot="4763838645" />
                </div>
            </div>
        );
    }
}
