// --- Post bootstrap -----
import React from "react";
import ProductCategories from "./modules/views/ProductCategories";
import ProductSmokingHero from "./modules/views/ProductSmokingHero";
import AppFooter from "./modules/views/AppFooter";
import ProductHero from "./modules/views/ProductHero";
import ProductHowItWorks from "./modules/views/ProductHowItWorks";
import ProductCTA from "./modules/views/ProductCTA";
import AppAppBar from "./modules/views/AppAppBar";
import SEO from "./SEO";
//<ProductValues />
function Index() {
    return (
        <React.Fragment>
            <SEO
                title="Free Music Video Maker"
                description="The best free music video maker online. No watermarks, forced sign-ups or payments. Create your music visualizer now!"
            />
            <AppAppBar />
            <ProductHero />
            <ProductCategories />
            <ProductHowItWorks />
            <ProductCTA />
            <ProductSmokingHero />
            <AppFooter />
        </React.Fragment>
    );
}

export default Index;
