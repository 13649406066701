import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import { Redirect } from "react-router-dom";
const backgroundImage = "./img/Background.png";

const easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
};

function scrollTo(duration) {
    const element = document.body;
    const to = document.getElementById("FeaturedProjectsTitle").offsetTop;

    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function () {
        currentTime += increment;
        var val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }

        console.log(val);
    };
    animateScroll();
}

const styles = (theme) => ({
    background: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center"
    },
    button: {
        minWidth: 200
    },
    h5: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(10)
        }
    },
    more: {
        marginTop: theme.spacing(2)
    }
});

class ProductHero extends React.PureComponent {
    state = { redirectTo: "" };
    render() {
        const { classes } = this.props;

        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo}></Redirect>;

        return (
            <ProductHeroLayout backgroundClassName={classes.background}>
                {/* Increase the network loading priority of the background image. */}
                <img style={{ display: "none" }} src={backgroundImage} alt="" />
                <Typography
                    color="inherit"
                    align="center"
                    variant="h2"
                    marked="center"
                >
                    Visualize your sound
                </Typography>
                <Typography
                    color="inherit"
                    align="center"
                    variant="h5"
                    className={classes.h5}
                >
                    Make professional visuals directly in your browser.
                    <br />
                    No payments, watermarks or forced sign-ups!
                </Typography>
                <Button
                    color="secondary"
                    variant="contained"
                    size="large"
                    //href="#FeaturedProjects"
                    className={classes.button}
                    style={{ textAlign: "center", color: "white" }}
                    onClick={() => scrollTo(500)}
                    //onClick={() => this.setState({ redirectTo: "/editor" })}
                >
                    {" "}
                    Get started{" "}
                </Button>

                <a
                    href="/editor"
                    style={{ marginTop: 2, color: "rgba(255,255,255,0.6)" }}
                >
                    {" "}
                    (Open empty project)
                </a>

                <Typography
                    variant="body2"
                    color="inherit"
                    className={classes.more}
                >
                    Scroll down to view featured templates
                </Typography>
            </ProductHeroLayout>
        );
    }
}

ProductHero.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductHero);

/*
  <Button
                            color="secondary"
                            variant="contained"
                            size="large"
                            //href="#FeaturedProjects"
                            className={classes.button}
                            style={{ textAlign: "center", color: "white" }}
                            onClick={() => scrollTo(500)}
                            //onClick={() => this.setState({ redirectTo: "/editor" })}
                        >
                            {" "}
                            Get started{" "}
                        </Button>

                        <a href="/editor"> (Open empty project)</a>

                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    className={classes.button}
                    style={{
                        textAlign: "center",
                        marginTop: 10,
                        width: 255,
                        backgroundColor: "#3333AA",
                        fontSize: "0.825rem"
                    }}
                    onClick={() => this.setState({redirectTo: "/downloads"})}
                >
                    Download desktop client
                </Button>*/
