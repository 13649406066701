// --- Post bootstrap -----
import React from "react";
import AppAppBar from "./modules/views/AppAppBar";
import AppFooter from "./modules/views/AppFooter";
import FeaturedVideos from "./modules/views/FeaturedVideos";
import { VerticalAd } from "home/Ad";

function Videos() {
    return (
        <React.Fragment>
            <AppAppBar />
            <div style={{ display: "flex" }}>
                <div>
                    <VerticalAd slot="5825638870" />
                    <VerticalAd slot="5825638870" />
                </div>
                <div style={{ width: "60%" }}>
                    <FeaturedVideos />
                </div>
                <div>
                    <VerticalAd slot="5825638870" />
                    <VerticalAd slot="5825638870" />
                </div>
            </div>
            <AppFooter />
        </React.Fragment>
    );
}

export default Videos;
