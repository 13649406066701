import firebase from "firebase/app";
import "firebase/firestore"; // If using Firebase database

import "firebase/auth"; // If using Firebase database

var config = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID
};

const app = firebase.initializeApp(config);
const base = app.firestore();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const googleProvider = new firebase.auth.GoogleAuthProvider();

function increment(path) {
    const incrementField = app.firestore().FieldValue.increment(1);

    const ref = app.firestore().collection("stats").doc("global");
    ref.update({ [path]: incrementField });
}

export { base, app, facebookProvider, googleProvider, increment };
